@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Quattrocento Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p {
  margin: 5px; /* Reduces vertical spacing to 5px */
}
h3 {
  margin: 5px; /* Reduces vertical spacing to 5px */
}
h1 {
    margin: 5px;
    font-size: 20px;
    font-weight: 700;
  }
ul {
  margin: 5px; /* Reduces vertical spacing to 5px */
}
